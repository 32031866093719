export const useDeviceType = () => {
  let mobileDevice;
  const isClient = typeof window.location !== 'undefined'

  if (isClient) {
    let firefoxIsMobile = window.navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
    let universalMobileDetect = window.navigator.userAgentData?.mobile

    if (!!firefoxIsMobile && !!universalMobileDetect) {
      mobileDevice = true
    } else {
      mobileDevice = false
    }
  }

  return {
    isMobile: mobileDevice
  }
}