import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { HorizontalLogo } from '../../_svgs/HorizontalLogo/HorizontalLogo'
import { HeaderSearch } from '../HeaderSearch/HeaderSearch'
import { Form } from '../../_forms/Form/Form'
import { Pin } from '../../_svgs/Pin/Pin'
import { HeaderGreenBanner } from '../HeaderGreenBanner/HeaderGreenBanner'
import { useDispatch, useSelector } from 'react-redux'
import { sharedSetInstanceAction } from '../../../actions/sharedActions'
import { headerInfo } from '../../../actions/headerActions'
import Cookie from 'js-cookie'
import { HeaderMenuButtons } from '../HeaderMenuButtons/HeaderMenuButtons'
import { ReactHeaderFixedBottom } from '../ReactHeaderFixedBottom/ReactHeaderFixedBottom'
import { MobileLogoIconOnly } from '../../_svgs/MobileLogoIconOnly/MobileLogoIconOnly'
import { CategoryNav } from '../CategoryNav/CategoryNav'
import { TRIGGER_CLOSE_DROPDOWN } from '../../../constants/headerConstants'
import { HeaderOverlay } from '../../Overlay/Overlay'
import { utagLink } from '../../../tealium'
import { EasyRefill } from '../../_svgs/EasyRefill/EasyRefill'
import { useDeviceType } from '../../../hooks/useDeviceType'
import { TransparentButton } from '../../_inputs/Button/Button'
import { Phone } from '../../_svgs/Phone/Phone'
import { cartAction, openCartDialogAction } from '../../../actions/cartAction'
import { SHARED_CLEAR_INSTANCE } from '../../../constants/sharedConstants'

export const Header = () => {
  const dispatch = useDispatch()
  const cartCount = useSelector(state => state.header?.cartCount ? state.header.cartCount : 0)
  const showOverlay = useSelector(state => state.header?.showOverlay ? state.header.showOverlay : false)
  const [ displayStickerHeader, toggleStickyHeader ] = useState(false)
  const [isPWA, setIsPWA] = useState(false)

  const additionalAfterAddToCartCode = (product) => {
    dispatch({ type: SHARED_CLEAR_INSTANCE })
    dispatch(openCartDialogAction(product, 1, false))
  }

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 200) {
          toggleStickyHeader(true)
        } else {
          toggleStickyHeader(false)
        }
      })
      document.addEventListener("DOMContentLoaded", () => {
        if (document.querySelector('.shpSCtrack-scAdd-react')) {
          let atcBtns = document.querySelectorAll('.shpSCtrack-scAdd-react')
          for (let atcbi = 0; atcbi < atcBtns.length; atcbi++) {
            atcBtns[atcbi].addEventListener('click', (e) => {
              e.preventDefault()
              let product = {
                productPartNumber: atcBtns[atcbi].getAttribute('data-product-id'),
                productDiscountPrice: atcBtns[atcbi].getAttribute('data-cost'),
                productName: atcBtns[atcbi].getAttribute('data-content'),
                productVendor: atcBtns[atcbi].getAttribute('data-vendor'),
                productDetails: atcBtns[atcbi].getAttribute('data-details'),
                bogo: atcBtns[atcbi].getAttribute('data-bogo'),
                percentDiscount: atcBtns[atcbi].getAttribute('data-discount'),
                tooLowToShow: atcBtns[atcbi].getAttribute('data-lowToShow'),
                everyDayLowPrice: atcBtns[atcbi].getAttribute('data-lowPrice')
              }
              dispatch(cartAction(
                product.productPartNumber,
                1,
                () => { additionalAfterAddToCartCode(product) },
                false,
                false,
                { PageName: 'myaccount-order_details' }
              ))
            })
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    dispatch(headerInfo())
    if (navigator && window) {
      setIsPWA(navigator.standalone || window.matchMedia('(display-mode: standalone)').matches)
      if (!(navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches)) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/sw.js', { scope: '/' }).then(function (reg) {
            console.log('Successfully registered service worker', reg)
          }).catch(function (err) {
            console.warn('Error whilst registering service worker', err)
          })
        }
        if (Cookie.get('homeScreen')) {
          Cookie.set('homeScreen', parseInt(Cookie.get('homeScreen')) + 1, { expires: 30 })
        } else {
          Cookie.set('homeScreen', 1, { expires: 30 })
        }
        if (parseInt(Cookie.get('homeScreen')) === 3) {
          dispatch(sharedSetInstanceAction({
            name: 'homeScreenModal',
            data: {}
          }))
        }
      }
    }
  }, [])

  const openHandler = () => {
    if (window) {
      const { isMobile } = useDeviceType()
      if (isMobile) {
        window.__attentive.trigger("","","", 981558)
      } else {
        window.__attentive.trigger("","","", 981572)
      }
    }
    utagLink({
      'tealium_event': 'email-sms-popup-trigger'
    })
  }
  
  const mobileSearch = () => {
    // do nothing
  }

  const closeDropDown = () => {
    dispatch({ type: TRIGGER_CLOSE_DROPDOWN, payload: true })
  }

  const pwaBackClickHandler = () => {
    if (window) {
      window.history.back()
    }
  }

  return (
    <ReactHeaderFocusDiv data-site-area='header' id='reactHeader'>
      <ReactHeader role='search'>
        <HeaderGreenBanner emailOpenHandler={openHandler}/>
        <ReactHeaderContainer>
          <ReactHeaderContainerDiv>
            <ReactHeaderUpperContainer>
              {isPWA &&
                <PWABtnContainer>
                  <ReactHeaderPWABackBtn onClick={pwaBackClickHandler}>&lt; Back</ReactHeaderPWABackBtn>
                </PWABtnContainer>
              }
              <ReactHeaderLogoContainer id='header-logo'>
                <LogoA href='/' className='bypassInterstitialPage swanson-logo-tracking' data-tracking='header|swanson-logo' aria-label='Swanson Health logo Home'>
                  <HorizontalLogo styles={css`@media (max-width: 1279px) { display: none; }`}/>
                  <MobileLogoIconOnly width={40} styles={css`@media (min-width: 1280px) { display: none;}`}/>
                </LogoA>
              </ReactHeaderLogoContainer>
              <ReactHeaderSearchContainer
                styles={isPWA ? '' : css`@media (max-width: 1279px) { flex: 1 0 auto; }`}
              >
                <Form
                  WrappedForm={HeaderSearch}
                  fieldId='headerSearchDesktop'
                  mobileSearch={mobileSearch}
                  labelNextToField={true}
                />
              </ReactHeaderSearchContainer>
              <HeaderMenuButtons cartCount={cartCount}/>
            </ReactHeaderUpperContainer>
            <ReactHeaderStoreCSContainer>
              <ReactHeaderStoreA
                href='/store-locator'
                data-testid='storelocator'
                data-tracking='header|store-locator'
                aria-label='Find Swanson at a Store Near You'
              >
                <Pin fill='#000'/>&nbsp;Find Swanson at a Store Near You
              </ReactHeaderStoreA>
              <ReactHeaderCustServDiv>
                <ReactHeaderCustServA
                  href='tel:1-800-824-4491'
                  aria-label='call 1-800-824-4491'
                >
                  <Phone/>1-800-824-4491
                </ReactHeaderCustServA>
              </ReactHeaderCustServDiv>
            </ReactHeaderStoreCSContainer>
            <ReactHeaderLinksContainer>
              <ReactHeaderA
                href='/landing-pages/rewards.html'
                data-tracking='header|rewards'
                aria-label='Wellness Rewards'
                styles={css`
                  display: none;
                  @media (max-width: 743px) {
                    display: inline-block;
                  }
                `}
              >
                Rewards
              </ReactHeaderA>
              <ReactHeaderERefillA
                href='/lp/easyrefill.html'
                data-tracking='header|easy-refill'
                aria-label='Easy Refill'
              >
                <EasyRefill
                  height={15}
                  width={77}
                />
              </ReactHeaderERefillA>
              <ReactHeaderA
                href='/landing-pages/rewards.html'
                data-tracking='header|rewards'
                aria-label='Wellness Rewards'
                styles={css`
                  display: none;
                  @media (max-width: 1279px) and (min-width: 743px){
                    display: inline-block;
                  }
                `}
              >
                Rewards
              </ReactHeaderA>
              <ReactHeaderA
                href='/blog'
                data-tracking='header|blog-link'
                aria-label='Swanson health hub and blog'
                styles={css`
                  @media(max-width: 1279px) {
                    display: none;
                  }
                `}
              >
                Blog
              </ReactHeaderA>
              <ReactHeaderA
                href='/landing-pages/rewards.html'
                data-tracking='header|rewards'
                aria-label='Wellness Rewards'
                styles={css`
                  display: none;
                  @media (min-width: 1279px) { 
                    display: inline-block
                  }
                `}
              >
                Rewards
              </ReactHeaderA>
              <ReactHeaderA
                href='/Brands'
                data-tracking='header|brands'
                aria-label='Brands'
                styles={css`
                  @media (max-width: 743px) {
                    display: none;
                  }
                `}
              >
                Brands
              </ReactHeaderA>
              <ReactHeaderA
                href='/web-specials'
                data-tracking='header|specials'
                aria-label='Web Specials'
                styles={css`
                  @media (max-width: 1279px) {
                    padding: 0 1rem;
                  }
                `}
              >
                Specials
              </ReactHeaderA>
              <ReactHeaderCustServDiv
                styles={css`
                  display: none; 
                  @media (min-width: 744px) and (max-width: 1279px) {
                    display: flex;
                    justify-content: center;
                  }
                  @media (min-width: 390px) and (max-width: 743px) {
                    display: flex;
                    width: auto;
                  }
                `}
              >
                <ReactHeaderCustServContainer>
                  <ReactHeaderCustServA
                    href='tel:1-800-824-4491'
                    aria-label='call 1-800-824-4491'
                  >
                    <Phone/>1-800-824-4491
                  </ReactHeaderCustServA>
                </ReactHeaderCustServContainer>
              </ReactHeaderCustServDiv>
            </ReactHeaderLinksContainer>
          </ReactHeaderContainerDiv>
          <HeaderOverlay fromTop={displayStickerHeader ? '0px' : '160px'} position={displayStickerHeader ? 'fixed' : 'absolute'} backgroundColor='rgba(0,0,0, 0.6)' zIndex='85' isVisible={showOverlay} onClick={closeDropDown} />
        </ReactHeaderContainer>
      </ReactHeader>
      <CategoryNav topId='topId'/>
      <ReactHeaderFixedBottom cartCount={cartCount} emailOpenHandler={openHandler}/>
      {displayStickerHeader &&
        <ReactHeader
          styles={css`
            @media (max-width: 1279px) {
              display: none;
            }
          `}
        >
          <ReactHeaderContainer
            styles={css`
              height: auto;
              position: fixed;
              top: 0px;
              background-color: ${props => props.theme.white};
              z-index: 102;
              border-bottom: 1px solid ${props => props.theme.darkerLightGrey}; 
            `}
          >
            <ReactHeaderContainerDiv>
              <ReactStickySearchHeaderDiv>
                {isPWA &&
                  <PWABtnContainer>
                    <ReactHeaderPWABackBtn onClick={pwaBackClickHandler}>&lt; Back</ReactHeaderPWABackBtn>
                  </PWABtnContainer>
                }
                <ReactHeaderLogoContainer
                  id='sticky-header-logo'
                  styles={css`
                    margin-right: 4.8rem;
                  `}
                >
                  <LogoA href='/' className='bypassInterstitialPage swanson-logo-tracking' data-tracking='header|swanson-logo' aria-label='Swanson Health logo Home'>
                    <HorizontalLogo />
                  </LogoA>
                </ReactHeaderLogoContainer>
                <ReactHeaderSearchContainer
                  styles={isPWA ? '' : css`@media (max-width: 1279px) { flex: 1 0 auto; }`}
                >
                  <Form
                    WrappedForm={HeaderSearch}
                    fieldId='stickyHeaderSearchDesktop'
                    mobileSearch={mobileSearch}
                    labelNextToField={true}
                    searchFormId='sticky-header-searchForm'
                  />
                </ReactHeaderSearchContainer>
                <HeaderMenuButtons cartCount={cartCount}/>
              </ReactStickySearchHeaderDiv>
            </ReactHeaderContainerDiv>
          </ReactHeaderContainer>
        </ReactHeader>
      }
    </ReactHeaderFocusDiv>
  )
}

const ReactStickySearchHeaderDiv = styled.div`
  position: sticky;
  display: flex;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.breakpoints.desktop};
  }
  @media (max-width: 1279px) {
    padding: 0.8rem 0;
  }
`

const ReactHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.darkerLightGrey};
  background-color: ${props => props.theme.white};
  @media (max-width: 1279px) {
    top: 0;
    z-index: 101;
  }
  ${props => props.styles};
`

const ReactHeaderContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.breakpoints.desktop};
  }  
  @media (max-width: 1279px) {
    padding: 0.5rem;
    justify-content: center;
  }
  @media (max-width: 743px) {
    align-items: center;
  }
`

const ReactHeaderContainer = styled.div`
  width: 100%;
  position: relative;
  ${props => props.styles};
`

const ReactHeaderA = styled.a`
  color: ${props => props.theme.black};
  text-decoration: none;
  display: flex;
  padding: 0.9rem 1.8rem;
  line-height: 1.8rem;
  align-items: center;
  &:hover {
    background-color: ${props => props.theme.buttercupYellow};
    border-radius: 0.8rem;
    color: ${props => props.theme.black};
    text-decoration: none;
  }
  @media (max-width: 390px) {
    padding: 0;
  }
  @media (min-width: 392px) and (max-width: 1279px) {
    font-size: 1.4rem;
    padding: 0 0.9rem;
  }
  ${props => props.styles};
`

const ReactHeaderCustServDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 389px) {
    display: none;
  }
  ${props => props.styles};
`

const ReactHeaderLogoContainer = styled.div`
  display: flex;
  order: 0;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1279px) {
    margin-right: 1rem;
  }
  @media (max-width: 743px) {
    width: 5.6rem;
  }
  ${props => props.styles};
`

const ReactHeaderSearchContainer = styled.div`
  display: flex;
  width: 55%;
  align-items: center;
  @media (max-width: 1279px) {
    width: initial;
  }
  @media (max-width: 743px) {
    padding-left: 1.6rem;
  }
  ${props => props.styles};
`

const ReactHeaderStoreCSContainer = styled.div`
  display: flex;
  order: 3;
  width: 50%;
  justify-content: flex-start;
  @media (max-width: 1279px) {
    display: none;
  }
`

const ReactHeaderLinksContainer = styled.div`
  display: flex;
  width: 50%;
  order: 4;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1279px) {
    justify-content: space-around;
    order: 2;
    width: initial;
  }
  @media (max-width: 743px) {
    margin-top: 1.2rem;
    max-width: 50rem;
    width: 100%;
  }
`

const ReactHeaderStoreA = styled.a`
  padding: 0 0.9rem;
  margin-right: 1.6rem;
  align-items: center;
  color: ${props => props.theme.black};
  text-decoration: none;
  display: flex;
  line-height: 1.8rem;
  &:hover {
    color: ${props => props.theme.black} !important;
    background-color: ${props => props.theme.white};
    text-decoration: underline !important;
    border-radius: 0.8rem;
  }
`

const ReactHeaderERefillA = styled.a`
  color: ${props => props.theme.black};
  text-decoration: none;
  display: flex;
  padding: 0.9rem 1.8rem;
  line-height: 1.8rem;
  align-items: center;
  &:hover {
    background-color: ${props => props.theme.buttercupYellow};
    border-radius: 0.8rem;
    text-decoration: none;
    @media (min-width: 850px) and (max-width: 1279px) {
      padding: 0 1rem;
    }
    @media (max-width: 743px) {
      padding: 0.9rem;
    }
    span {
      color: ${props => props.theme.black};
    }
  }
  @media (max-width: 389px) {
    padding: 0;
  }
  @media (max-width: 1279px) {
    padding: 0 1rem;
  }
  ${props => props.styles};
`

const LogoA = styled.a`
  padding-left: 0.8rem;
  @media (max-width: 743px) {
    padding-left: 1.6rem;
  }
`

const ReactHeaderFocusDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  button, a, input {
    &:focus {
      outline: 1px solid black;
    }
  }
`

const ReactHeaderCustServA = styled.a`
  color: #2F711C;
  text-decoration: none !important;
  &:hover {
    color: ${props => props.theme.primary} !important;
    text-decoration: underline !important;
  }
  @media (min-width: 392px) and (max-width: 1279px) {
    font-size: 1.4rem;
  }
`

const ReactHeaderCustServContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  @media (min-width: 391px) and (max-width: 392px) {
    white-space: nowrap;  
  }
`
const PWABtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ReactHeaderPWABackBtn = styled(TransparentButton)`
  display: flex;
  font-size: 1.6rem;
  color: ${props => props.theme.primary};
`

const ReactHeaderUpperContainer = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 1280px) {
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 1279px) and (min-width: 744px){
    flex: 1;
  }
  @media (max-width: 743px) {
    width: 100%;
    justify-content: space-between;
  }
`