import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { OutOfStockModal } from '../OutOfStockModal/OutOfStockModal'
import { ATCModal } from '../ATCModal/ATCModal'
import { SignUpModal } from '../_header/SignUpModal/SignUpModal'
import { HomeScreenModal } from '../_header/HomeScreenModal/HomeScreenModal'
import { SHARED_CLEAR_INSTANCE } from '../../constants/sharedConstants'
import { utagLink } from '../../tealium'
import { YouTubeVideoModal } from '../YouTubeVideoModal/YouTubeVideoModal'
import { AgeVerifyModal } from '../AgeVerifyModal/AgeVerifyModal'
import { RefusedAgeVerifyModal } from '../RefusedAgeVerifyModal/RefusedAgeVerifyModal'
import { AddToFavSignInModal } from '../AddToFavSignInModal/AddToFavSignInModal'
import { SlideInLogin } from '../_customer-portal/SlideInLogin/SlideInLogin'

export const Instances = () => {
  const instance = useSelector(state => state ? state.shared.instance : null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (instance.name !== '') {
      if (instance.data.kickStartTrigger) {
        instance.data.kickStartTrigger()
      }
    }
  }, [instance.name])

  if (instance && instance.name === 'atcModal') {
    utagLink({
      'tealium_event': 'atc_popup_trigger'
    })
  }

  const closeHandler = () => {
    dispatch({ type: SHARED_CLEAR_INSTANCE })
  }
  return (
    <>
      {instance && instance.name === 'OutOfStockModal' &&
        <OutOfStockModal
          productName={instance.data.productName}
          productPartNumber={instance.data.productPartNumber}
          productPrice={instance.data.productDiscountPrice}
        />
      }
      {instance && instance.name === 'atcModal' &&
        <ATCModal
          product={instance.data.product}
          quantity={instance.data.quantity}
          updateCart={instance.data.updateCart}
        />
      }
      {instance && instance.name === 'emailSignUp' &&
        <SignUpModal
          defaultTab={instance.data.defaultTab}
          closeHandler={instance.data.closeHandler}
          formLocation={instance.data.formLocation}
          closeHandlerAfter={instance.data.closeHandlerAfter}
        />
      }
      {instance && instance.name === 'homeScreenModal' && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream &&
        <HomeScreenModal
          closeHandler={closeHandler}
        />
      }
      {instance && instance.name === 'youTubeModal' &&
        <YouTubeVideoModal
          videoID={instance.data.videoID}
          closeHandler={instance.data.closeHandler}
        />
      }
      {instance && instance.name === 'ageVerifyModal' &&
        <AgeVerifyModal
          additionalAddToCartCode={instance.data.additionalAddToCartCode}
          updateCart={instance.data.updateCart}
          partNumber={instance.data.partNumber}
          quantity={instance.data.quantity}
          displayATCBanner={instance.data.displayATCBanner}
          ogChecked={instance.data.ogChecked}
          additionalAfterAddToCartCode={instance.data.additionalAfterAddToCartCode}
          ogOffer={instance.data.ogOffer}
        />
      }
      {instance && instance.name === 'refusedAgeVerifyModal' &&
        <RefusedAgeVerifyModal />
      }
      {instance && instance.name === 'addToFavSignIn' &&
        <AddToFavSignInModal
          loginHref={instance.data.loginHref}
          registerHref={instance.data.registerHref}
        />
      }
      {instance && instance.name === 'slideInLogin' &&
        <SlideInLogin />
      }
    </>
  )
}
