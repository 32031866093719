import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import styled, { css } from 'styled-components'
import { CarouselProduct } from '../CarouselProduct/CarouselProduct'
import { LoadAnimation } from '../LoadAnimation/LoadAnimation'
import { AnimateFromNone } from '../AnimateFromNone/AnimateFromNone'
import { utagLink } from '../../tealium'
import { CircleChevron } from '../_svgs/CircleChevron/CircleChevron'
import { gql, useClient } from 'urql'
import { showNotificationWithTimeout } from '../../actions/notificationActions'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import useAmplitude from '~/hooks/useAmplitude'

export const PDPCarouselContainer = ({
  endpoint,
  maxProducts = 12,
  carouselType,
  index = 0,
  updateCart = false,
  atcProdId,
  showHeader = true,
  prodPerPage = 4,
  containerSectionStyles,
  parentDivStyles,
  carouselContainerStyles,
  useGraphQL = true,
  gqlType,
  productID
}) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const carouselProductContainer = useRef()
  const ref = useRef()
  const isInitialRender = useRef(true)
  const [title, setTitle] = useState('Loading...')
  const [MKSActionId, setMKSActionId] = useState()
  const [productList, setProductList] = useState([])
  const isSM = useMediaQuery({ maxWidth: 768, minWidth: 576 })
  const isMD = useMediaQuery({ maxWidth: 992, minWidth: 768 })
  const isL = useMediaQuery({ minWidth: 992 })
  const pcIsL = useMediaQuery({ minWidth: 1200 })
  const pcIsMD = useMediaQuery({ minWidth: 768, maxWidth: 1290 })
  const [stateProductPartNumber, setStateProductPartNumber] = useState([])
  const [numberOfPages, setNumberOfPages] = useState(stateProductPartNumber.length)
  const [productsPerPage, setProductsPerPage] = useState(prodPerPage)
  const client = useClient()
  const [startIndex, setStartIndex] = useState(0)
  const [finalIndex, setFinalIndex] = useState(3)
  const [constructorRecommendationData, setConstructorRecommendationData] = useState({
    resultId: '',
    request: {
      pod_id: '',
      num_results: 0
    }
  })

  const {trackEvent: trackAmplitudeEvent, mapAmplitudeProduct} = useAmplitude()

  const handleAmplitudeEvent = product => {
    trackAmplitudeEvent('carousel product clicked', {
      ProductDetails: [mapAmplitudeProduct(product)],
      HeadlineText: title,
      PodID: constructorRecommendationData.request['pod_id']
    })
  }

  const handleAmplitudeCarouselViewed = () => {
    const HeadlineText = ref.current?.dataset?.carouselTitle
    const PodID = ref.current?.dataset?.carouselPodId

    if (HeadlineText !== 'Loading...') {
      trackAmplitudeEvent('carousel viewed', {
        HeadlineText,
        PodID
      })
    }
  }

  useEffect(() => {
    if (isMD || pcIsL) {
      setProductsPerPage(3)
      setFinalIndex(3)
      setNumberOfPages(stateProductPartNumber.length - 3)
    } else if (isSM || pcIsMD) {
      setProductsPerPage(2)
      setFinalIndex(2)
      setNumberOfPages(stateProductPartNumber.length - 2)
    } else {
      setProductsPerPage(1)
      setFinalIndex(1)
      setNumberOfPages(stateProductPartNumber.length - 1)
    }
    setStartIndex(0)
    setPage(0)
  }, [stateProductPartNumber.length, isMD, isL, isSM, pcIsL, pcIsMD])

  const getItems = () => {
    //Accepted gqlType strings are: cart, emptySearch, cartmodal, homepage, productdetail
    if (useGraphQL && gqlType) {
      const CAROUSEL_STRING = gql`
        query ($carouselType: String!, $productID: String) {
          getRecommendation(type: $carouselType, id: $productID) {
            recommendationResultList {
              title
              actionId
              originalRecommendationRequest
              result {
                adobeRecord {
                  constructorOptions
                  productDiscountPrice
                  discount
                  productPartNumber
                  masterGroupItem
                  newWebItem
                  bogo
                  longURL
                  productName
                  productVendor
                  productDetails
                  percentDiscount
                  rating
                  numReviews
                  everyDayLowPrice
                  tooLowToShow
                  statusUnavailable
                  flags
                  rewards2x
                  rewards3x
                  rewards4x
                }
              }
            }
          }
        }`
      client
        .query(CAROUSEL_STRING, {
          carouselType: gqlType,
          productID: productID
        })
        .toPromise()
        .then(result => {
          if (result?.error?.networkError) {
            dispatch(
              showNotificationWithTimeout({
                time: 5000,
                background: 'warn',
                notificationText:
                  'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
                active: true
              })
            )
          } else {
            setProductList(result.data.getRecommendation.recommendationResultList[index].result)
            setTitle(result.data.getRecommendation.recommendationResultList[index].title)
            setMKSActionId(result.data.getRecommendation.recommendationResultList[index].actionId)

            const recommendationList = result.data?.getRecommendation?.recommendationResultList

            if (recommendationList?.[index]?.originalRecommendationRequest !== null) {
              try {
                const originalRecommendationRequest = JSON.parse(recommendationList[index].originalRecommendationRequest)
                setConstructorRecommendationData(originalRecommendationRequest)
              } catch (e) {
                setConstructorRecommendationData({
                  resultId: '',
                  request: {
                    pod_id: '',
                    num_results: 0
                  }
                })
              }
            }

            setStateProductPartNumber(result.data.getRecommendation.recommendationResultList[index].result.slice(0, maxProducts))
            const event = new window.Event('xhr-loaded')
            document.dispatchEvent(event)
            let tealiumObj = {tealium_event: 'carousel_loaded'}
            if (atcProdId) {
              tealiumObj['atc_modal_prod_id'] = atcProdId
            }
            utagLink(tealiumObj)
          }
        })
        .then(() => {
          handleAmplitudeCarouselViewed()
        })
    } else {
      axios
        .get(endpoint)
        .then(response => {
          setProductList(response.data.getRecommendation.recommendationResultList[index].result)
          setTitle(response.data.recommendationResultList[index].title)

          const recommendationResult = response.data.recommendationResultList[index]

          if (recommendationResult && recommendationResult.setConstructorRecommendationData) {
            try {
              const constructorRecommendationData = JSON.parse(recommendationResult.setConstructorRecommendationData)
              if (constructorRecommendationData !== undefined) {
                setConstructorRecommendationData(constructorRecommendationData)
              }
            } catch (e) {
              setConstructorRecommendationData({
                resultId: '',
                request: {
                  pod_id: '',
                  num_results: 0
                }
              })
            }
          }

          setMKSActionId(response.data.recommendationResultList[index].actionId)
          setStateProductPartNumber(response.data.recommendationResultList[index].result.slice(0, maxProducts))
          const event = new window.Event('xhr-loaded')
          document.dispatchEvent(event)
          let tealiumObj = {tealium_event: 'carousel_loaded'}
          if (atcProdId) {
            tealiumObj['atc_modal_prod_id'] = atcProdId
          }
          utagLink(tealiumObj)
        })
        .then(() => {
          handleAmplitudeCarouselViewed()
        })
    }
  }

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    } else {
      if (carouselProductContainer.current.querySelector('#productFocus')) {
        carouselProductContainer.current.querySelector('#productFocus').focus()
      }
    }
  }, [page])

  const buildProductSections = numberPerSection => {
    let productSections = []
    let carouselProducts = []
    for (let z = startIndex; z < finalIndex; z++) {
      if (stateProductPartNumber[z]) {
        const constructorOptions =
          stateProductPartNumber[z].adobeRecord?.constructorOptions
        if (constructorOptions !== null) {
          try {
            stateProductPartNumber[z].adobeRecord.constructorOptions = JSON.parse(constructorOptions)
          } catch (e) {
            console.log(e)
          }
        }
      }
      const carouselProduct =
        typeof stateProductPartNumber[z]?.adobeRecord !== 'undefined' &&
        numberPerSection > 0 ? (
          <CarouselProduct
            focus={z / numberPerSection === page}
            onClick={() =>
              handleAmplitudeEvent(stateProductPartNumber[z]?.adobeRecord)
            }
            product={stateProductPartNumber[z].adobeRecord}
            styles={css`
              border-radius: 0.5rem;
              overflow: hidden;
              @media (max-width: 768px) {
                flex: 1 1 100%;
              }
            `}
            index={z}
            actionId={MKSActionId}
            carouselName={title}
            classes='react-carousel_Product'
            updateCart={updateCart}
            usePDPProd
          />
        ) : null
      if (carouselProduct) {
        carouselProducts.push(carouselProduct)
      }
    }
    productSections.push(
      <AnimateFromNone
        visible
        width={false}
        styles={css`
          gap: 1rem;
        `}
      >
        {carouselProducts}
      </AnimateFromNone>
    )
    return productSections
  }

  useEffect(() => {
    let observer
    let didCancel = false
    if (ref && stateProductPartNumber.length === 0) {
      if (typeof IntersectionObserver !== 'undefined') {
        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                // do ajax and display contents
                getItems()
                observer.unobserve(ref.current)
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: '0%'
          }
        )
        observer.observe(ref.current)
      } else {
        // Old browsers fallback
        getItems()
      }
    }
    return () => {
      didCancel = true
      // on component cleanup, we remove the listener
      if (observer && observer.unobserve) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  const forwardHandler = () => {
    if (page !== numberOfPages) {
      setPage(page + 1)
      setStartIndex(startIndex + 1)
      setFinalIndex(finalIndex + 1)
    }
  }

  const backHandler = () => {
    if (page !== 0) {
      setPage(page - 1)
      setStartIndex(startIndex - 1)
      setFinalIndex(finalIndex - 1)
    }
  }

  return (
    <ContainerSection
      id='productCarousel'
      data-carousel-title={title}
      data-carousel-pod-id={constructorRecommendationData.request['pod_id']}
      data-cnstrc-recommendations
      data-cnstrc-recommendations-pod-id={constructorRecommendationData?.request['pod_id']}
      data-cnstrc-num-results={constructorRecommendationData?.request['num_results']}
      data-cnstrc-result-id={constructorRecommendationData?.resultId}
      styles={containerSectionStyles}
      ref={ref}
    >
      <CarouselHeadlineContainer>
        {showHeader && <Headline>{title}</Headline>}
        {productList.length > 1 && (
          <ChevronDiv className='carousel-pagination'>
            <ChevronButton
              className='back'
              disabled={page === 0}
              aria-label='previous slide'
              data-testid='back'
              title='back'
              onClick={backHandler}
            >
              <CircleChevron
                borderStroke='#D7D2D2'
                backgroundFill='#D7D2D2'
                arrowFill='#545454'
              />
            </ChevronButton>
            <ChevronButton
              className='forward'
              disabled={page === productList.length - 1}
              aria-label='next slide'
              data-testid='forward'
              title='forward'
              onClick={forwardHandler}
            >
              <CircleChevron
                borderStroke='#D7D2D2'
                backgroundFill='#D7D2D2'
                arrowFill='#545454'
                point='right'
              />
            </ChevronButton>
          </ChevronDiv>
        )}
      </CarouselHeadlineContainer>
      <CarouselProductParentDiv styles={parentDivStyles}>
        <CarouselContainerDiv
          styles={carouselContainerStyles}
          data-testid='carouselContainer'
          data-id={carouselType}
          data-title={title}
          className='real-react-carousel'
          ref={carouselProductContainer}
        >
          {buildProductSections(productsPerPage)}
          {stateProductPartNumber.length === 0 && <LoadAnimation />}
        </CarouselContainerDiv>
      </CarouselProductParentDiv>
    </ContainerSection>
  )
}

const ContainerSection = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 85rem;
  margin-left: auto;
  margin-right: auto;
  @media print {
    display: none;
  }
  ${props => props.styles};
`

const CarouselProductParentDiv = styled.div`
  position: relative;
  ${props => props.styles};
`

const CarouselContainerDiv = styled.div`
  display: flex;
  align-items: center;
  ${props => props.styles};
`

const ChevronDiv = styled.div`
  display: flex;
  justify-content: center;
`

export const ChevronButton = styled.button`
  background-color: transparent;
  width: 6rem;
  border: none;
  cursor: pointer;

  &:hover {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`

const Headline = styled.h2`
  font-weight: 500;
  font-size: 2.4rem;
`

const CarouselHeadlineContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 1rem;
`
