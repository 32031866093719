import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { LightBox } from '../LightBox/LightBox'
import { LightBoxCentered, LightBoxSM } from '../_styled/LightBoxOptions'
import styled, { css } from 'styled-components'
import { OptionButton } from '../_inputs/OptionButton/OptionButton'
import {
  cartAction,
  ageVerifiedAction
} from '../../actions/cartAction'
import { updateCartFormAction } from '../../actions/updateCartDataAction'
import { InfoIcon } from '../_svgs/InfoIcon/InfoIcon'
import { sharedSetInstanceAction } from '../../actions/sharedActions'
import Cookie from 'js-cookie'

export const AgeVerifyModal = ({
  additionalAddToCartCode,
  updateCart,
  partNumber,
  quantity,
  displayATCBanner,
  ogChecked,
  ogOffer,
  additionalAfterAddToCartCode
}) => {
  const dispatch = useDispatch()
  const [ tooltipClicked, setClick ] = useState(false)

  const closeHandler = () => {
    if (document) {
      document.body.style.overflow = 'auto'
    }
    if (tooltipClicked === false) {
      dispatch(sharedSetInstanceAction({
        name: 'refusedAgeVerifyModal',
        data: {}
      }))
    }
  }

  const verifyHandler = data => {
    if (data?.value === 'true') {
      dispatch(ageVerifiedAction(true))
      Cookie.set('ageVerified', true, { expires: 7 })
      if (additionalAddToCartCode) {
        additionalAddToCartCode()
      }
      if (updateCart) {
        dispatch(updateCartFormAction({
          action: 'add',
          id: partNumber,
          qty: '1',
          ogOffer: ogOffer
        }, additionalAfterAddToCartCode))
      } else {
        dispatch(cartAction(partNumber, quantity, additionalAfterAddToCartCode, displayATCBanner, ogChecked, ogOffer))
      }
    }
  }

  const tooltipHandler = () => {
    setClick(!tooltipClicked)
  }

  return (
    <LightBox closeHandler={closeHandler} show styles={css`${LightBoxCentered}${LightBoxSM}`}>
      <DisclaimerDiv>Let's verify your age before we add this product to your cart.
        <InfoTooltipDiv onClick={tooltipHandler}>
          <InfoIcon
            color={'#fff'}
            styles={css`
            background-color: ${props => props.theme.primary}; 
            border-radius: 9999px;`}
            width='15px'
            height='15px'
          />
          {tooltipClicked &&
            <LightBox closeHandler={tooltipHandler} show styles={css`${LightBoxCentered}`}>
                <span>
                  The item you are purchasing requires age verification. Please verify you are over the age of 18 to add this item to your cart.
                </span>
            </LightBox>
          }
        </InfoTooltipDiv>
      </DisclaimerDiv>
      <OptionButton
        name='Age Verify Checkbox'
        returnState={verifyHandler}
        value
        inputType='checkbox'
        labelText={'I\'m at least 18 years of age.'}
        labelStyles={css`justify-content: center;`}
      />
    </LightBox>
  )
}

export const DisclaimerDiv = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoTooltipDiv = styled.span`
  display: inline-block;
  cursor: pointer;
`