import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Field } from '../../_inputs/Field/Field'
import { Button } from '../../_inputs/Button/Button'
import { Col4, FluidImage } from '../../_styled/BootStrapGrid'
import { OptionButton } from '../../_inputs/OptionButton/OptionButton'
import { useDispatch, useSelector } from 'react-redux'
import { submitOOSAction } from '../../../actions/sharedActions'
import { Star } from '../../_svgs/Star/Star'
import { TriangleWarning } from '../../_svgs/TriangleWarning/TriangleWarning'
import {
  SET_SECOND_LOADING,
  SHARED_CHECK_EMAIL,
  UPDATE_OOS_EMAIL_SHOW,
  UPDATE_OOS_MESSAGE,
  UPDATE_OOS_SUBMITTED
} from '../../../constants/sharedConstants'
import { CircleWarning } from '../../_svgs/CircleWarning/CircleWarning'
import { LoadAnimation } from '../../LoadAnimation/LoadAnimation'
import { ERROR_REMOVE } from '../../../constants/errorConstants'
import Reaptcha from 'reaptcha'

export const OutOfStockForm = ({
  productName,
  productPartNumber,
  formOnly = false,
  backorder = false,
  allowExtraSubmit= false,
  hideOfferOption = false,
  boldedProductName = false,
  fieldSubmitContainerStyles = css``,
  btnStyles = css`margin: 1.2rem 0rem;`
}) => {
  const dispatch = useDispatch()
  const reCaptchaRef = useRef()
  const submitted = useSelector(state => state.shared?.oosSubmitted ? state.shared.oosSubmitted : false)
  const message = useSelector(state => state.shared?.oosMessage ? state.shared.oosMessage : (state.errors?.email && submitted ? state.errors.email : ''))
  const [ email, setEmail ] = useState('')
  const [ emailSignupChecked, setEmailSignupChecked ] = useState(false)
  const showEmailSignupMsg = useSelector(state => state.shared?.oosEmailShow ? state.shared.oosEmailShow : false)
  const loading = useSelector(state => state.shared?.secondLoading ? state.shared.secondLoading : false)

  const captchaOnVerify = recaptchaResponse => {
    dispatch(submitOOSAction({
      email: email,
      partNumber: productPartNumber,
      oosEmailSignup: emailSignupChecked
    }, recaptchaResponse))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch({type: SET_SECOND_LOADING, payload: true})

    if (showEmailSignupMsg) {
      dispatch({type:UPDATE_OOS_EMAIL_SHOW, payload: false})
    }

    if (submitted) {
      dispatch({type: UPDATE_OOS_SUBMITTED, payload: false})
    }

    if (message) {
      dispatch({type: UPDATE_OOS_MESSAGE, payload: ''})
      dispatch({type: ERROR_REMOVE})
    }

    if (checkEmail) {
      dispatch({type: SHARED_CHECK_EMAIL, payload: false})
    }
    reCaptchaRef.current.execute()
  }

  const catchTextChange = (e) => {
    setEmail(e.value)
  }

  const oosEmailSignupChangeHandler = (e) => {
    setEmailSignupChecked(e.value === 'true')
  }

  const checkEmail = useSelector(state => state.shared?.sharedCheckEmail ? state.shared.sharedCheckEmail : false)

  useEffect(() => {
    if ((submitted || message) && loading) {
      dispatch({type: SET_SECOND_LOADING, payload: false})
      reCaptchaRef.current.reset()
    }
  }, [submitted])

  return (
    <Form onSubmit={handleSubmit}>
      <Reaptcha
        ref={reCaptchaRef}
        sitekey={window.reCaptchaKey}
        onVerify={captchaOnVerify}
        size='invisible'
      />
      {!formOnly &&
      <ImgContainer>
        <Img
          src={`https://media.swansonvitamins.com/images/items/150/${productPartNumber}.jpg`}
          alt={productName}
        />
      </ImgContainer>
      }

      {loading ?
        <LoadAnimationContainer>
          <LoadAnimation/>
        </LoadAnimationContainer>
        :
        <FormOnlyDiv>
          {submitted ?
            <>
              <OosFormH2 data-testid='oos-thank-you-msg'>Thank You!</OosFormH2>
              <OosThankYouP styles={showEmailSignupMsg ? css`padding-bottom: 0;` : ''}>We&apos;ll let you know as soon
                as&nbsp;<OosFormTextSpan styles={css`font-size: 1.9rem;`}>{productName}</OosFormTextSpan>&nbsp;is back
                in stock.</OosThankYouP>
              {allowExtraSubmit && <OosThankYouP>Add another email address</OosThankYouP>}
              {showEmailSignupMsg && checkEmail === 'success' &&
                <OosThankYouMsgContainer data-testid='oos-new-signup-msg'>
                  <OosThankYouMsgDiv color='#00833d'>
                    <Star styles={css`margin-right: 1rem;`} height='2rem' width='2rem'/>
                    You're now signed up to receive exclusive deals & savings opportunities!
                  </OosThankYouMsgDiv>
                </OosThankYouMsgContainer>
              }
              {showEmailSignupMsg && checkEmail === 'error' &&
                <OosThankYouMsgContainer data-testid='oos-already-signup-msg'>
                  <OosThankYouMsgDiv color='#FC000F'>
                    <TriangleWarning styles={css`margin-right: 0.5rem;`} height='3rem' width='3rem'/>
                    This email address is already signed up for deals & news.
                  </OosThankYouMsgDiv>
                </OosThankYouMsgContainer>
              }
              {showEmailSignupMsg && checkEmail !== 'error' && checkEmail !== 'success' &&
                <OosThankYouMsgContainer>
                  <OosThankYouMsgDiv
                    color='#FC000F'
                    styles={css`
                      max-width: 60.8rem;
                      text-align: center;
                    `}
                  >
                    <CircleWarning
                      styles={css`margin-right: 0.5rem;`}
                    />
                    Oops! We are having trouble processing your request. Our apologies! Please try again later.
                  </OosThankYouMsgDiv>
                </OosThankYouMsgContainer>
              }
            </>
            :
            <OosFormTextContainerDiv
              styles={css`
                margin-top: ${backorder ? '0rem' : '1.2rem'};
                font-size: ${backorder ? '1.6rem' : '2.1rem'};
              `}
            >
              {formOnly ?
                <>
                  {backorder ? 'Or let' : 'Let'} us email you when {boldedProductName ? <b>{productName}</b> : 'it'} is back on our shelves!
                </>
                :
                <>
                  Let us email you when {productName} is back on our shelves!
                </>
              }
            </OosFormTextContainerDiv>
          }
          {(!(submitted && showEmailSignupMsg && checkEmail === 'success') || allowExtraSubmit) &&
            <OosFormFieldContainerDiv formContainerStyles>
              {message &&
              <OosThankYouMsgContainer>
                <OosThankYouMsgDiv
                  color='#FC000F'
                  styles={css`
                    max-width: 60.8rem;
                    text-align: center;
                    margin: 0 0 1.5rem 0;
                  `}
                >
                  <CircleWarning
                    styles={css`margin-right: 0.5rem;`}
                  />
                  A valid email is required. Please try again.
                </OosThankYouMsgDiv>
              </OosThankYouMsgContainer>
              }
              {(!submitted || allowExtraSubmit) &&
                <FieldAndSubmitContainerDiv styles={fieldSubmitContainerStyles}>
                  <OosFieldContainer>
                    <Field
                      dataTestid='oos-form-enter-email'
                      labelText='Let us email you when it is back on our shelves!'
                      showLabel={false}
                      placeholder='Please enter your email.'
                      name='email'
                      styles={css`
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                      `}
                      labelStyles={css`
                        width: 100%;
                      `}
                      fieldStyles={css`
                        height: 4rem;
                        padding-left: 1.6rem;
                        border-radius: 1rem;
                        border: 0.1rem solid ${props => props.theme.mediumGrey};
                        width: 100%;
                        ${showEmailSignupMsg && checkEmail === 'success' && 'padding-top: 1rem;'}
                        &::placeholder {
                          color: ${props => props.theme.mediumGrey};
                          line-height: 20px;
                          font-size: 1.6rem;
                        }
                      `}
                      returnState={catchTextChange}
                    />
                  </OosFieldContainer>
                  <OosFormOptionButtonContainerDiv
                    styles={css`
                      ${hideOfferOption && `
                        width: auto;
                        @media (max-width: ${props => props.theme.breakpoints.lg}) {
                          width: 100%;
                        }
                      `}
                    `}
                  >
                    {!hideOfferOption &&
                      <OOSOptionButtonContainer>
                        <OptionButton
                          useBox={true}
                          inputType='checkbox'
                          name='oosEmailSignup'
                          returnState={oosEmailSignupChangeHandler}
                          labelText='Send me emails about specials, discounts, new products and offers.'
                          value='true'
                          classes='justify-content-center mt-2 mb-2'
                          checked={emailSignupChecked}
                          testId='oosEmailSignup'
                          styles={css`
                            justify-content: center;
                            display: flex;
                            width: 100%;
                          `}
                          labelStyles={css`
                            font-size: 1.6rem;
                            font-weight: 400;
                            line-height: 112.5%;
                          `}
                          inputStyles={css`
                            border-radius: 8px;
                          `}
                        />
                      </OOSOptionButtonContainer>
                    }
                    <div>
                      <OOSSecondaryButton
                        styles={btnStyles}
                      >
                        Notify Me
                      </OOSSecondaryButton>
                    </div>
                  </OosFormOptionButtonContainerDiv>
                </FieldAndSubmitContainerDiv>
              }
            </OosFormFieldContainerDiv>
          }
        </FormOnlyDiv>
      }
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  width: 100%;
`

const LoadAnimationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 25.6rem;
`

const FormOnlyDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`

const Img = styled.img`
  ${FluidImage};
`

const ImgContainer = styled.div`
  ${Col4};
`

const OosFormH2 = styled.h2`
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 3.3rem;
  justify-content: center;
  margin-bottom: 1.2rem;
`

const OosThankYouP = styled.div`
  font-size: 1.9rem;
  padding-bottom: 1rem;
  width: 100%;
  text-align: center;
  ${props => props.styles}
`

const OosFormTextContainerDiv = styled.div`
  font-weight: 400;
  width: 100%;
  color: ${props => props.theme.black};
  margin-bottom: 1.2rem;
  line-height: 104.76%;
  ${props => props.styles};
`

const OosFormFieldContainerDiv = styled.div`
  width: 100%;
`
const OosFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const OosFormTextSpan = styled.span`
  color: ${props => props.theme.primary};
  ${props => props.styles}
`

const OOSSecondaryButton = styled(Button)`
  background-color: ${props => props.theme.darkestOrange};
  border: none;
  border-radius: .8rem;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 124.44%;
  &:active {
    background-color: ${props => props.theme.darkestOrange};
  }
  ${props => props.styles};
  &:hover {
    background-color: ${props => props.theme.yellow};
    border: none;
    color: ${props => props.theme.black};
  }
`

const OosFormOptionButtonContainerDiv = styled.div`
  width: 100%;
  ${props => props.styles};
`

const OosThankYouMsgDiv = styled.div`
  padding: 1.4rem 1rem;
  border: 1px dashed ${props => props.color};
  color: ${props => props.color};
  margin-top: 2.2rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  ${props => props.styles}
`

const OosThankYouMsgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const OOSOptionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`

const FieldAndSubmitContainerDiv = styled.div`
  ${props => props.styles};
`
